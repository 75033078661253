export default [
  {
    header: '营销管理',
    action: 'read',
    resource: 'ecom',
  },
  {
    title: '推广管理',
    icon: 'CodesandboxIcon',
    children: [
      {
        title: '物料管理',
        route: 'material',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '推广员管理',
        route: 'promoterlist',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '活动活码',
        route: 'activityqrcode',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '群活码',
        route: '',
        action: 'read',
        resource: 'wechat',
      },
     
    ],
  },
  {
    title: '群发任务',
    icon: 'SendIcon',
    children: [
      {
        title: '朋友圈群发',
        route: 'SendCircle',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '智能群发',
        route: 'AiSend',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '关键字回复',
        route: 'KeyWordSend',
        action: 'read',
        resource: 'wechat',
      },
      
    ],
  },
  {
    title: '素材库',
    icon: 'FolderIcon',
    children: [
      {
        title: '图片素材',
        route: 'PicMaterial',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '视频素材',
        route: 'VideoMaterial',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '链接素材',
        route: 'LinkMaterial',
        action: 'read',
        resource: 'wechat',
      },
    ],
  },
  {
    title: '智能加粉',
    icon: 'UserPlusIcon',
    children: [
      {
        title: '用户池',
        route: 'Userpool',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '加粉日志',
        route: 'addfriendlog',
        action: 'read',
        resource: 'wechat',
      },
      {
        title: '任务管理',
        route: 'taskmanagement',
        action: 'read',
        resource: 'wechat',
      },
    ],
  },
]
