export default [
  {
    header: '电商管理',
    action: 'read',
    resource: 'ecom',
  },
  {
    title: '返利管理',
    icon: 'SunIcon',
    tag: '',
    tagVariant: 'light-success',
    action: 'read',
    resource: 'ecom',
    children: [
      {
        title: '账户配置',
        route: 'AccountConfig',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '群发配置',
        route: 'GroupConfig',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '产品列表',
        route: 'TbCommodity',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '产品文章',
        route: 'articleRelease',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '淘宝订单',
        route: 'TbOrders',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '京东订单',
        route: 'JdOrders',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '拼多多订单',
        route: 'PddOrders',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '美团订单',
        route: 'meituanorders',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '吃喝玩乐订单',
        route: 'jutuikeorder',
        action: 'read',
        resource: 'ecom',
      },
      
      {
        title: '抖音订单',
        route: 'DyOrders',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '电影票订单',
        route: 'moiveOrders',
        action: 'read',
        resource: 'ecom',
      },

    ],
  },
  {
    title: '财务管理',
    icon: 'TrendingUpIcon',
    children: [
      {
        title: '资金明细',
        route: 'FundRecords',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '提现管理',
        route: 'CashManger',
        action: 'read',
        resource: 'ecom',
      },
    ],
  },
  {
    title: '自营商城',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: '店铺管理',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '商品管理',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '库存管理',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '仓库管理',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '商品分类',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
      {
        title: '物流管理',
        route: '',
        action: 'read',
        resource: 'ecom',
      },
    ],
  },
]
