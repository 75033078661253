export default [
  {
    header: '企业管理',
    action: 'read',
    resource: 'bus',
  },
  {
    title: '部门管理',
    route: 'businessmanger',
    icon: 'CopyIcon',
    action: 'read',
    resource: 'bus',
  },
  {
    title: '人员管理',
    route: 'staffmanger',
    icon: 'PackageIcon',
    action: 'read',
    resource: 'bus',
  },
  {
    title: '部门标签',
    route: '',
    icon: 'PaperclipIcon',
    action: 'read',
    resource: 'bus',
  },
  {
    title: '部门术语',
    route: '',
    icon: 'CopyIcon',
    action: 'read',
    resource: 'bus',
  },
  {
    title: '系统配置',
    route: 'businessconfig',
    icon: 'SettingsIcon',
    action: 'read',
    resource: 'bus',
  },
]
